@import '../assets/styles/themes/color';
@import '../assets/styles/themes/light';

// table rwd config
table {
	border: 1px solid #ccc;
	border-collapse: collapse;
	margin: 0;
	padding: 0;
	width: 100%;
	table-layout: fixed;
}

table th,
table td {
	text-align: left;
}

table th {
	letter-spacing: 0.1em;
}

// @media screen and (max-width: 1000px) {
//   table {
//     border: 0;
//   }

//   table thead {
//     border: none;
//     clip: rect(0 0 0 0);
//     height: 1px;
//     margin: -1px;
//     overflow: hidden;
//     padding: 0;
//     position: absolute;
//     width: 1px;
//   }

//   table tr {
//     display: block;
//     margin-bottom: 24px;
//     background-color: white;
//     border-radius: 4px;
//     box-shadow: 0px 0px 20px rgb(37 65 178 / 25%);
//     padding: 0 16px;
//   }

//   table td {
//     display: block;
//     text-align: left;
//   }

//   table td::before {
//     /*
//     * aria-label has no advantage, it won't be read inside a table
//     content: attr(aria-label);
//     */
//     content: attr(data-label);
//     // float: left;
//     font-weight: bold;
//     display: block;
//     color: var(--gray-1);
//     margin-bottom: 5px;
//   }

//   table td:last-child {
//     border-bottom: 0;
//   }
// }

.history {
	max-width: 1200px;
	width: 90%;
	background: #ffffff;
	box-shadow: 0px 0px 20px rgba(37, 65, 178, 0.25);
	border-radius: 4px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);

	// @media screen and (max-width: 1000px) {
	//   box-shadow: none;
	//   background: transparent;
	//   position: relative;
	//   left: 0;
	//   top: 0;
	//   transform: unset;
	// }

	&-table {
		// width: 768px;
		width: 100%;
		border: 1px solid white;
		font-size: 14px;
		border: none;
		border-spacing: 0px;
		border-radius: 4px;

		@media screen and (max-width: 1100px) {
			display: block;
			overflow-x: auto;
		}

		& > thead {
			& > tr:nth-child(1) {
				& > th {
					font-weight: 700;
					padding-left: 16px;
					padding-right: 20px;
					padding-bottom: 15px;
					padding-top: 15px;
					position: relative;

					@media screen and (max-width: 1100px) {
						white-space: nowrap;
					}
				}
			}
		}

		& > tbody {
			border-top: 1px solid var(--gray-4);

			& > tr {
				& > td {
					padding-top: 10px;
					padding-bottom: 10px;
					padding-left: 16px;

					// padding-right: 20px;
					@media screen and (max-width: 1100px) {
						// width: 100% !important;
						// padding-left: 0;
						// padding-right: 0;
						// border-bottom: 1px solid var(--gray-4);
						white-space: nowrap;
					}
				}
			}

			& > tr:nth-child(1) {
				& > td {
					padding-top: 20px;
				}
			}
		}

		&-line {
			background: linear-gradient(
				147.15deg,
				#19113e 29.03%,
				#041d75 84.89%
			);
			border-radius: 4px 4px 0px 0px;
			height: 8px;

			// @media screen and (max-width: 1000px) {
			//   display: none;
			// }
		}

		&-border {
			width: 1px;
			height: calc(100% - 30px);
			background-color: var(--gray-2);
			position: absolute;
			right: 0px;
			top: 50%;
			transform: translateY(-50%);
		}

		&-title,
		&-item {
			& a {
				color: var(--secondary-3) !important;
				position: relative;
				display: inline-block;

				&::after {
					content: '';
					position: absolute;
					bottom: 0px;
					left: 0px;
					width: 100%;
					height: 1px;
					background-color: var(--secondary-3);
				}

				@media screen and (max-width: 1000px) {
					//   display: inline-block;
				}
			}

			&-address {
				width: 11%;

				@media screen and (max-width: 1000px) {
					white-space: break-spaces !important;
				}

				& > a {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
			}

			&-time {
				width: 12%;
			}

			&-from {
				width: 18%;
			}

			&-to {
				width: 16%;
			}

			&-value {
				width: 11%;
				text-align: right;
				padding-right: 26px;
				position: relative;

				&-bonus {
					width: 16px;
					height: 16px;
					position: absolute;
					right: 0px;
					top: 14px;

					& > * {
						fill: var(--secondary-3);
					}
				}
			}

			&-fee {
				width: 8%;
				text-align: right;
				padding-right: 26px;
				position: relative;
			}

			&-token {
				width: 8%;
				text-align: center;
				padding-right: 20px;
			}

			&-status {
				width: 11%;

				& > p {
					font-weight: 700;

					&.pending {
						color: $color-function-3;
					}

					&.completed {
						color: $color-primary;
					}
				}
			}

			// @media screen and (max-width: 1000px) {

			//   &-value,
			//   &-token {
			//     text-align: left;
			//   }
			// }
		}

		&-topline {
			&_tr {
				display: none;

				// @media screen and (max-width: 1000px) {
				//   display: block;
				//   margin-bottom: 0;
				//   background: linear-gradient(147.15deg,
				//       #19113e 29.03%,
				//       #041d75 84.89%);
				//   border-radius: 4px 4px 0px 0px;
				//   height: 8px;
				// }
			}
		}
	}
}

.no_history {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;

	& > p {
		margin-top: 12px;
		font-weight: 700;
		font-size: 30px;
		background: linear-gradient(147.15deg, #19113e 29.03%, #041d75 84.89%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		background-clip: text;
		text-fill-color: transparent;
	}
}

// pagination style

.pagination {
	display: flex;
	justify-content: center;
	align-items: center;
	border-top: 1px solid var(--gray-4);
	padding-top: 20px;
	margin-top: 10px;
	margin-bottom: 20px;

	@media screen and (max-width: 1000px) {
		border-top: unset;
	}
}

.page-item {
	list-style: none;
	margin: 0 6px;
	cursor: pointer;

	&.active {
		border: 1px solid
			linear-gradient(147.15deg, #19113e 29.03%, #041d75 84.89%);
		border-radius: 4px;
		padding: 0 4px;
	}
}
